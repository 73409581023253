import React from 'react';
import { AlertTriangle, FileSearch, Zap, Users, ExternalLink } from 'lucide-react';

document.title = 'Defend Tech';

const Header = () => (
  <header className="bg-gray-800 text-white p-4">
    <div className="container mx-auto flex justify-between items-center">
      <h1 className="text-2xl font-bold">Defend Tech</h1>
      <nav>
        <ul className="flex space-x-4">
          <li><a href="#home" className="hover:text-gray-300">Home</a></li>
          <li><a href="#services" className="hover:text-gray-300">Services</a></li>
          <li><a href="#about" className="hover:text-gray-300">About</a></li>
          <li><a href="#contact" className="hover:text-gray-300">Contact</a></li>
        </ul>
      </nav>
    </div>
  </header>
);

const Hero = () => (
  <section id="home" className="bg-blue-600 text-white py-20">
    <div className="container mx-auto text-center">
      <h2 className="text-4xl font-bold mb-4">Defending Your Digital Assets</h2>
      <p className="text-xl mb-8">Expert and affordable cybersecurity services tailored for your business needs</p>
      <a href="https://forms.gle/uCKxj5MXmgM3ztoSA" target="_blank" rel="noopener noreferrer" className="bg-white text-blue-600 py-2 px-6 rounded-full text-lg font-semibold hover:bg-gray-100">
        Get a Free Consultation
      </a>

    </div>
  </section>
);

const ServiceCard = ({ icon: Icon, title }) => (
  <div className="bg-white p-6 rounded-lg shadow-md flex flex-col items-center text-center hover:shadow-lg transition-shadow duration-300">
    <Icon className="text-blue-600 w-16 h-16 mb-4" />
    <h3 className="text-xl font-semibold">{title}</h3>
  </div>
);

const Services = () => (
  <section id="services" className="py-16 bg-gray-100">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-12">Our Services</h2>
      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        <ServiceCard icon={Zap} title="Incident Response & Forensics" />
        <ServiceCard icon={FileSearch} title="Maturity Assessment" />
        <ServiceCard icon={AlertTriangle} title="Vulnerability Assessment" />
        <ServiceCard icon={FileSearch} title="Architecture Review" />
        <ServiceCard icon={Users} title="Security Training" />
        <ServiceCard icon={Users} title="IT Services and Support" />
      </div>
    </div>
  </section>
);

const About = () => (
  <section id="about" className="py-16">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold text-center mb-8">About Defend Tech</h2>
      <p className="text-lg text-center max-w-3xl mx-auto">
        Defend Tech is a cybersecurity consulting firm specializing in Vulnerability Assessment, Incident Response, Architecture Reviews, and Security Training. Our team of experienced professionals is dedicated to protecting your business from evolving digital threats, ensuring your peace of mind in an increasingly complex cyber landscape.
      </p>
    </div>
  </section>
);

const Contact = () => (
  <section id="contact" className="py-16 bg-gray-100">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-3xl font-bold mb-8">Contact Us</h2>
      <p className="text-lg mb-8 max-w-2xl mx-auto">Ready to enhance your cybersecurity? Click below to access our service request form.</p>
      <a 
        href="https://forms.gle/uCKxj5MXmgM3ztoSA" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="inline-flex items-center bg-blue-600 text-white py-3 px-6 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300"
      >
        Request Our Services
        <ExternalLink className="ml-2 h-5 w-5" />
      </a>
    </div>
  </section>
);

const Footer = () => (
  <footer className="bg-gray-800 text-white py-8">
    <div className="container mx-auto text-center">
      <p>&copy; 2024 Defend Tech. All rights reserved.</p>
    </div>
  </footer>
);

const App = () => (
  <div className="font-sans">
    <Header />
    <Hero />
    <Services />
    <About />
    <Contact />
    <Footer />
  </div>
);

export default App;


